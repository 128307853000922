import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class EditComponent extends React.Component{

 constructor(props){
   super(props);
   this.state = {
      laneId: "",
      title:"",
      campLabel:""
   }
 } 

 render(){
  return (
    <div>
      <div className="form-row justify-content-center">
        <div className="form-group col-md-4">
            <label htmlFor="inputPassword4">Lane ID</label>
            <input type="text" className="form-control"  placeholder="Lane ID"
              value={this.state.laneId} onChange={(value)=> this.setState({laneId:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Título"
              value={this.state.title} onChange={(value)=> this.setState({title:value.target.value})}/>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Rótulo"
              value={this.state.campLabel} onChange={(value)=> this.setState({campLabel:value.target.value})}/>
          </div>
      </div>
      <button type="submit" className="btn btn-primary" onClick={()=>this.sendSave()}>Salvar</button>
      {'   '}
      <button type="submit" className="btn btn-primary" onClick={()=>this.handleClick()}>Listar Lanes</button>
    </div>
  );
}

handleClick () {
  this.props.history.push("/listLane");
}

sendSave(){

  if (this.state.laneId==="") {
    alert("O campo lane ID não pode estar vazio.")
  }
  else if (this.state.title==="") {
     alert("O campo título não pode estar vazio.")
  }
  else if (this.state.campLabel==="") {
    alert("O campo rótulo não pode estar vazio.")
  }
  else {

    const datapost = {
      laneId : 'lane' + this.state.laneId,
      title : this.state.title,
      label : this.state.campLabel
    }

    axios.post(baseUrl + "/lanes/create",datapost)
    .then(response=>{
      if (response.data.success===true) {
        alert(response.data.message)
      }
      else {
        alert(response.data.message)
      }
    }).catch(error=>{
      alert("Error 34 "+ error)
    })

  }

}

}


export default EditComponent;