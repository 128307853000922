import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Link } from 'react-router-dom';

//sweetalert2
import 'sweetalert2/src/sweetalert2.scss'

class listComponent extends React.Component  {
    
  render()
  {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
              <Link style={{margin:'2px'}} className="btn btn-info " to="/listLane">Lanes</Link>
              {'   '}
              <Link style={{margin:'2px'}} className="btn btn-info " to="/listCard">Cards</Link>
          </div>
        </nav>
      <br></br>
      <hr></hr>
      </div>
    );
  }

}

export default listComponent;