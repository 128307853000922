import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import axios from 'axios';

import { matchPath } from 'react-router'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class EditComponent extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      dataLane:{},
      laneId: "",
      ticketId: "",
      title:"",
      description:"",
      label:""
    }
  }
  

  componentDidMount(){

    const match = matchPath(this.props.history.location.pathname, {
      path: '/editCard/:param',
      exact: true,
      strict: false
    })

    let userId = match.params.param;
    //console.log(userId);
    const url = baseUrl+"/cards/get/"+userId
    axios.get(url)
    .then(res=>{
      if (res.data.success) {
        const data = res.data.data[0]
        this.setState({
          dataLane:data,
          laneId:data.laneId.replace('lane',''),
          ticketId:data.ticketId,
          title:data.title,
          description:data.description,
          label:data.label
        })
      }
      else {
        alert("Error web service")
      }
    })
    .catch(error=>{
      alert("Error server "+error)
    })
  }

  render(){
    return (
      <div>
        <div className="form-row justify-content-center">
          <div className="form-group col-md-3">
            <label htmlFor="inputPassword4">Lane ID</label>
            <input type="text" className="form-control"  placeholder="Lane ID"
              value={this.state.laneId} onChange={(value)=> this.setState({laneId:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Ticket ID</label>
            <input type="text" className="form-control"  placeholder="Ticket ID"
              value={this.state.ticketId} onChange={(value)=> this.setState({ticketId:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Título</label>
            <input type="text" className="form-control"  placeholder="Título"
              value={this.state.title} onChange={(value)=> this.setState({title:value.target.value})}/>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Rótulo</label>
            <input type="text" className="form-control"  placeholder="Rótulo"
              value={this.state.label} onChange={(value)=> this.setState({label:value.target.value})}/>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4">Descrição</label>
            <textarea 
              className="form-control"
              name="description" 
              cols="40" 
              rows="5"
              value={this.state.description} 
              onChange={(value)=> this.setState({description:value.target.value})}
              required="required"
              placeholder="Olá, tudo bem?&#13;&#10;Como posso te ajudar?&#13;&#10;Abraços, a gente se vê!"
            ></textarea>
            {/* <input type="text" className="form-control"  placeholder="Descrição"
              value={this.state.description} onChange={(value)=> this.setState({description:value.target.value})}/> */}
          </div>
        </div>
        <button type="submit" className="btn btn-primary" onClick={()=>this.sendUpdate()}>Atualizar</button>
        {'   '}
        <button type="submit" className="btn btn-primary" onClick={()=>this.handleClick()}>Listar Cards</button>
      </div>
    );
  }

  handleClick () {
    this.props.history.push("/listCard");
  }

  sendUpdate(){
   
    const match = matchPath(this.props.history.location.pathname, {
      path: '/editCard/:param',
      exact: true,
      strict: false
    })

    let userId = match.params.param;
    
    // parametros de datos post
    const datapost = {
      laneId : 'lane' + this.state.laneId,
      ticketId: this.state.ticketId,
      title : this.state.title,
      description : this.state.description,
      label : this.state.label
    }

    axios.post(baseUrl + "/cards/update/"+userId,datapost)
    .then(response=>{
      if (response.data.success===true) {
        alert(response.data.message)
      }
      else {
        alert("Error")
      }
    }).catch(error=>{
      alert("Error 34 "+error)
    })

   }

}


export default EditComponent;